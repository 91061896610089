// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"
import "bootstrap"

//Desabilitando aquele scroll pro topo o redirecionamento do turbolinks caso o link esteja embaixo da página

/*

window.addEventListener('turbolinks:load', function() {
  document.querySelector('html').style.scrollBehavior = 'smooth'
});
window.addEventListener('turbolinks:before-visit', function() {
  document.querySelector('html').style.scrollBehavior = 'unset'
});

*/


//UPDATEEEEEEEEEEEEE: DESABILITEI O TURBOLINKS, TAVA DANDO MUITO PROBLEMA, VOU MONITORAR A PERFORMANCE (Linha 12 e código da 22 a 27)
