import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { chatroomId: Number, currentUserId: Number, currentWorkerId: Number, workType: String, workId: String }
  static targets = ["messages", "form", "input"]

  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: "ChatroomChannel", id: this.chatroomIdValue, kind: this.currentWorkerIdValue },
      { received: data => this.#insertMessageAndScrollDown(data) }
    )

    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
  }

  #insertMessageAndScrollDown(data) {
    // Logic to know if the sender is the current_user
    const currentUserIsSender = this.currentWorkerIdValue === -1 ? this.currentUserIdValue === data.u_id : this.currentWorkerIdValue === data.w_id

    // Creating the whole message from the `data.message` String
    const messageElement = this.#buildMessageElement(currentUserIsSender, data.message)

    // Inserting the `message` in the DOM
    this.messagesTarget.insertAdjacentHTML("beforeend", messageElement)
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
  }

  #justifyClass(currentUserIsSender) {
    return currentUserIsSender ? "justify-content-end" : "justify-content-start"
  }

  #userStyleClass(currentUserIsSender) {
    return currentUserIsSender ? "sender-style" : "receiver-style"
  }

  #buildMessageElement(currentUserIsSender, message) {
    return `
      <div class="message-row d-flex ${this.#justifyClass(currentUserIsSender)}">
        <div class="${this.#userStyleClass(currentUserIsSender)}">
          ${message}
        </div>
      </div>
    `
  }

  resetForm(event){
    setTimeout(() => {
      this.formTarget.reset();
    }, "10")
  }

  disconnect() {
    const counter = document.querySelector(`#counter-${this.workTypeValue}-${this.workIdValue}`)

    if (counter !== null) {
        counter.innerHTML = "<p class='mt-4 mb-4'><span class='ms-1 me-1 mt-3 mb-3'>📩</span> Mensagens novas: <strong>0</strong></p>"
    }

    this.channel.unsubscribe()
  }
}
