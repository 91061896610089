// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

// import { Controller } from "stimulus"

// let intervalIds = [];

// export default class extends Controller {
//   connect() {
//     var activeIndex = 0;
//     var totalBanners = $('.banners-div > div').length - 1;

//     intervalIds.forEach(function(intervalId, index) {
//       clearInterval(intervalId);
//       intervalIds.splice(index, 1)
//     });

//     const banners = [
//       "https://res.cloudinary.com/getacar/image/upload/v1678048874/banners%20mobile/banner_1_mobile_yjhrr1.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1678048764/banners%20mobile/banner_2_mobile_kpf8fx.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1678048131/banners%20mobile/banner_3_mobile_dkl5x7.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1678048140/banners%20mobile/banner_4_mobile_yhskzi.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683768174/banners%20pc/banner_1_pc_kiqzpy.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683768261/banners%20pc/banner_2_pc_jzzm4n.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683768327/banners%20pc/banner_3_pc_vert2g.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683768360/banners%20pc/banner_4_pc_xpxb9k.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683769314/banners%20wide/banner_1_wide_ghoile.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683769466/banners%20wide/banner_2_wide_vaijmm.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683769872/banners%20wide/banner_3_wide_lnq8gp.png",
//       "https://res.cloudinary.com/getacar/image/upload/v1683769823/banners%20wide/banner_4_wide_oicaqr.png"
//     ];

//     // Pré-carregar as imagens
//     const imagePromises = banners.map((imageUrl) => {
//       return new Promise((resolve, reject) => {
//         const image = new Image();
//         image.onload = resolve;
//         image.onerror = reject;
//         image.src = imageUrl;
//       });
//     });

//     Promise.all(imagePromises)
//       .then(() => {
//         const bannerElements = Array.from($('.banners-div > div'));

//         function toggleBanner() {
//           $(bannerElements[activeIndex]).fadeToggle(1000, function() {
//             $(this).toggleClass('d-none');
//           });

//           activeIndex++;
//           if (activeIndex === totalBanners) {
//             activeIndex = 0;
//           }

//           $(bannerElements[activeIndex]).fadeToggle(1000, function() {
//             $(this).toggleClass('d-none');
//           });
//         }

//         //toggleBanner(); // Exibir o primeiro banner imediatamente

//         intervalIds.push(setInterval(toggleBanner, 7000));

//         console.log(intervalIds)
//       })
//       .catch((error) => {
//         console.error("Erro ao pré-carregar as imagens:", error);
//       });
//   }

//   disconnect() {
//     intervalIds.forEach(function(intervalId, index) {
//       clearInterval(intervalId);
//       intervalIds.splice(index, 1)
//     });
//   }
// }







import { Controller } from "stimulus"

export default class extends Controller {
  //static values = { bannerId: Number }

  connect() {
    // let allDivsInsideBannerDiv = Array.from($('.banners-div > div'));
    // allDivsInsideBannerDiv.pop();
    // let bannerElements = allDivsInsideBannerDiv;
    // let randomBannerIndex = this.bannerIdValue


    // let banners1 = [
    //   "https://res.cloudinary.com/getacar/image/upload/v1678048874/banners%20mobile/banner_1_mobile_yjhrr1.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683768174/banners%20pc/banner_1_pc_kiqzpy.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683769314/banners%20wide/banner_1_wide_ghoile.png",
    // ];

    // let banners2 = [
    //   "https://res.cloudinary.com/getacar/image/upload/v1678048764/banners%20mobile/banner_2_mobile_kpf8fx.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683768261/banners%20pc/banner_2_pc_jzzm4n.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683769466/banners%20wide/banner_2_wide_vaijmm.png",
    // ];

    // let banners3 = [
    //   "https://res.cloudinary.com/getacar/image/upload/v1678048131/banners%20mobile/banner_3_mobile_dkl5x7.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683768327/banners%20pc/banner_3_pc_vert2g.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683769872/banners%20wide/banner_3_wide_lnq8gp.png",
    // ];

    // let banners4 = [
    //   "https://res.cloudinary.com/getacar/image/upload/v1678048140/banners%20mobile/banner_4_mobile_yhskzi.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683768360/banners%20pc/banner_4_pc_xpxb9k.png",
    //   "https://res.cloudinary.com/getacar/image/upload/v1683769823/banners%20wide/banner_4_wide_oicaqr.png"
    // ];

    // let bannersUrls = [
    //   banners1,
    //   banners2,
    //   banners3,
    //   banners4
    // ]

    // let windowWidth = window.innerWidth

    // let selectedBannerSizeIndex;

    // if (windowWidth >= 300 && windowWidth < 800) {
    //   selectedBannerSizeIndex = 0
    // } else if (windowWidth >= 800 && windowWidth < 2900) {
    //   selectedBannerSizeIndex = 1
    // } else {
    //   selectedBannerSizeIndex = 2
    // }

    // let selectedBannerUrl = [
    //   bannersUrls[randomBannerIndex][selectedBannerSizeIndex]
    // ]

    // console.log(selectedBannerUrl)

    // // Pré-carregar as imagens
    // const imagePromises = selectedBannerUrl.map((imageUrl) => {
    //   return new Promise((resolve, reject) => {
    //     const image = new Image();
    //     image.onload = resolve;
    //     image.onerror = reject;
    //     image.src = imageUrl;
    //   });
    // });

    // Promise.all(imagePromises)
    //   .then(() => {
    //     function activateRandomBanner() {
    //       $(bannerElements[randomBannerIndex]).toggleClass('d-none');
    //     }

    //     activateRandomBanner()
    //   })
    //   .catch((error) => {
    //     console.error("Erro ao pré-carregar as imagens:", error);
    //   });
  }

  disconnect() {
  }
}
