import { Controller } from "@hotwired/stimulus"
import Typed from 'typed.js';

export default class extends Controller {
  connect() {
    new Typed('#typed-el', {
      strings: ['"Diarista"', '"Conserto de televisão"', '"Manicure"', '"Cozinheira"', '"Professor particular"', '"Encanador"' ],
      typeSpeed: 50,
      bindInputFocusEvents: true,
      loop: true
    });
  }
}
