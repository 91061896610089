// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "suggestions" ]

  connect() {
    console.log('connected');
    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        this.hideSuggestions()
      }
    });
  }

  suggestions() {
    const query = this.inputTarget.value;
    const url = this.element.dataset.suggestionsUrl;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.requestSuggestions(query, url)
    }, 250);
  }

  requestSuggestions(query, url) {
    if (query.length === 0) {
      this.hideSuggestions();
      return;
    }

    this.showSuggestions();

    fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ query: query })
    }).then(response => {
      response.text().then((html) => {
        if (html.includes('Elastic::Transport::Transport::Error')) {
          return
        }
        document.querySelector('#suggestions').innerHTML = '';
        document.querySelector('#suggestions').insertAdjacentHTML("beforeend", html)
      })
    })
  }

  childClicked(event) {
    this.childWasClicked = this.element.contains(event.target);
  }

  showSuggestions() {
    this.suggestionsTarget.classList.remove('d-none');
  }

  hideSuggestions() {
    if (!this.childWasClicked) {
      this.suggestionsTarget.classList.add('d-none');
    }

    this.childWasClicked = false
  }
}
